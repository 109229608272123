import React from 'react';
import './ButtonStyled.css';
import { Button } from '@mui/material';


function ButtonStyled({
    text,
    handleClick,
    limitless
}) {

    return(
        <Button 
            className={limitless ? 'button-limitless' : 'button-box'} 
            variant="contained" 
            color="primary" 
            onClick={handleClick}>
                {text}
        </Button>
    )
}


export default ButtonStyled;