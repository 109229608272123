import React from 'react';
import './ToxiDetail.css';
import Card from '../../elements/component/Card/Card';
import { useTranslation } from 'react-i18next';
import Torri from './../../elements/img/torredonjimeno..jpg';
import Vistalegre from './../../elements/img/vistaalegre.jpg';
import Twist from './../../elements/img/twist.jpg';
import Plaza from './../../elements/img/plaza.jpg';
import Madrono from './../../elements/img/madrono.jpg';
import Elpatio from './../../elements/img/elpatio.jpg';
import Apeadero from './../../elements/img/apeadero.jpg';

function ToxiDetail() {
  const { t } = useTranslation();

  const createGoogleMapUrl = address => {
    const url = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
    return url;
  }

  const handleButtonClick = (address) => {
    const elm = document.createElement('a');

    elm.href = createGoogleMapUrl(address);
    elm.target = '_blank';
    elm.rel = 'noopener noreferrer';

    elm.click();
  }


  return (
    <div className="ToxiDetail">
      <Card
        image={Torri}
        title='toxi-title'
        description='toxi-description'
        imagePosition="left" // or "right"
      />
      <Card
        image={Twist}
        title='twist-title'
        description='twist-description'
        buttonText={t('location-btn')}
        onButtonClick={() => handleButtonClick('Av. de Jaén, 132, 23650 Torredonjimeno, Jaén')}
      />
      <Card
        image={Vistalegre}
        title='vistalegre-title'
        description='vistalegre-description'
        buttonText={t('location-btn')}
        imagePosition="left" // or "right"
        onButtonClick={() => handleButtonClick('Avenida Jaén, 60, 23650, Torredonjimeno (Jaén)')}
      />
      <Card
        image={Plaza}
        title='plaza-title'
        description='plaza-description'
        buttonText={t('location-btn')}
        onButtonClick={() => handleButtonClick('Pl. Constitución, 1, BAJO, 23650 Torredonjimeno, Jaén')}
      />
      <Card
        image={Madrono}
        title='madrono-title'
        description='madrono-description'
        buttonText={t('location-btn')}
        imagePosition="left" // or "right"
        onButtonClick={() => handleButtonClick('Cortijo El Madroño')}
      />
      <Card
        image={Elpatio}
        title='elpatio-title'
        description='elpatio-description'
        buttonText={t('location-btn')}
        onButtonClick={() => handleButtonClick('C. Méjico, 9, 23650 Torredonjimeno, Jaén')}
      />
      <Card
        image={Apeadero}
        title='apeadero-title'
        description='apeadero-description'
        buttonText={t('location-btn')}
        imagePosition="left" // or "right"
        onButtonClick={() => handleButtonClick('Tr.ª del Parque, 23650 Torredonjimeno, Jaén')}
      />
    </div>
  );
}

export default ToxiDetail;
