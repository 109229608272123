import React from 'react';
import './AboutUs.css';
import SvgTrayAnimation from './../../elements/component/svgTrayAnimation/SvgTrayAnimation.js';

function AboutUs() {
  return (
    <div className="AboutUs">
      <SvgTrayAnimation />
    </div>
  );
}

export default AboutUs;
