import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from './../../elements/img/logo.png';
import CountdownTimer from '../../elements/component/countdownTimer/CountdownTimer';
import './Home.css'


function Home() {
  const { t } = useTranslation();
  return (
    <div className='home-container'>
      <div className="home-title">
        <img className="home-logo" src={logo}></img>
        <h1 className="home-main-title">Tim & Ampi</h1>
        <p className='home-subtitle'>{t('home-getting-married')}</p>
      </div>
      <div className='home-counter'>
        <div className='home-separator'></div>
        <CountdownTimer className="home-counter" targetDate={"2024-09-28T12:30:00"} />
      </div>
    </div>
  );
}

export default Home;
