import React from 'react';
import { useTranslation } from 'react-i18next';
import './CalendarBtn.css'
import { Button } from '@mui/material';

const CalendarBtn = ({ title, description, location, startTime, endTime }) => {
  const formatDate = (date) => {
    return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
  };

  const { t } = useTranslation();

  const createGoogleCalendarUrl = () => {
    const startFormatted = formatDate(startTime);
    const endFormatted = formatDate(endTime);
    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(t(title))}&dates=${startFormatted}/${endFormatted}&details=${encodeURIComponent(t(description))}&location=${encodeURIComponent(location)}&sf=true&output=xml`;
    return url;
  };

  return (
    <a className='a-without-styles' href={createGoogleCalendarUrl()} target="_blank" rel="noopener noreferrer">
      <Button 
            className="button-calendar" 
            variant="contained" 
            color="primary">
                {t('calendar-btn')}
        </Button>
    </a>
  );
};

export default CalendarBtn;