import React from "react";
import './InputText.css'
import { TextField } from "@mui/material";

function InputText({error, type, value, handleChange, handleKeyDown, placeholderText}) {
    
    return (
        <TextField
        className={`textfield-box ${error ? 'shake' : ''}`}
        type={type}
        error={error}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholderText}
    />
    )
}

export default InputText;