// src/StarAnimation.jsx
import React from 'react';
import { gsap } from 'gsap';
import './StarAnimation.css';

const StarAnimation = ({imgSrc, idxClass, text, subText}) => {
  const starAnimationShow = () => {
        const classQuerySelector = `.anim-explode-container-${idxClass}`;
        let container = document.querySelector(classQuerySelector);
        let svg = container.querySelector(".anim-explode");
        let numberOfShapes = 12;
        
        let shapes = [
          "M62.43,122.88h-1.98c0-16.15-6.04-30.27-18.11-42.34C30.27,68.47,16.16,62.43,0,62.43v-1.98 c16.16,0,30.27-6.04,42.34-18.14C54.41,30.21,60.45,16.1,60.45,0h1.98c0,16.15,6.04,30.27,18.11,42.34 c12.07,12.07,26.18,18.11,42.34,18.11v1.98c-16.15,0-30.27,6.04-42.34,18.11C68.47,92.61,62.43,106.72,62.43,122.88L62.43,122.88z",
        ];
        
          let animatedShapes = [];
        
          for (var i = 0; i < numberOfShapes; i++) {
            let newElement = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            newElement.setAttribute("d", gsap.utils.random(shapes));
            newElement.style.fill = gsap.utils.random([
              "#aec8c2",
              "#bac2c7",
              "#6677a3",
            ]);
            svg.appendChild(newElement);
            animatedShapes.push(newElement);
          }
        
          function killShapes() {
            animatedShapes.forEach((shape) => {
              svg.removeChild(shape);
            });
          }
        
          gsap.set(animatedShapes, {
            transformOrigin: "center",
            scale: "random(0.05, 0.15)"
          });
        
          gsap.to(animatedShapes, {
            onComplete: killShapes,
            keyframes: [
              {
                rotate: "random(180, -180)",
                x: "random([-35, -20, -10, 10, 20, 35])",
                y: "random([-35, -20, -10, 10, 20, 35])",
                ease: "expo.out",
                duration: 4,
                stagger: {
                  amount: 0.1
                }
              },
              { opacity: 0, delay: -3 }
            ]
          });
  }
  return (
    <div className="animation-container" onAnimationEnd={starAnimationShow}>
        <div className={`link anim-explode-container-${idxClass}`}>
            <div
              className="img-container"
            >
              <img src={require(`./../../img/${imgSrc}`)}/>
              <svg className="anim-explode" role="presentational" viewBox="0 0 100 100"></svg>
            </div>
            <p className="title">{text}</p>
            <p className="subtitle">{subText}</p>
        </div>
    </div>
  );
};

export default StarAnimation;
