import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/login/Login.js';
import Home from './pages/home/Home.js';
import TravelInfo from './pages/TravelInfo/TravelInfo.js';
import Witnesses from './pages/Witnesses/Witnesses.js';
import DressCode from './pages/DressCode/DressCode.js';
import Presents from './pages/Presents/Presents.js';
import ToxiDetail from './pages/ToxiDetail/ToxiDetail.js';
import AboutUs from './pages/AboutUs/AboutUs.js';
import Program from './pages/Program/Program.js';
import Presentation from './pages/Presentation/Presentation.js';

import ProtectedRoute from './pages/protected/ProtectedRoute.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute/>}>
          <Route path="/home" element={<Home />}/>
          <Route path="/program" element={<Program />}/>
          <Route path="/about-us" element={<AboutUs />}/>
          <Route path="/witnesses" element={<Witnesses />}/>
          <Route path="/presents" element={<Presents />}/>
          <Route path="/dresscode" element={<DressCode />}/>
          <Route path="/toxi-detail" element={<ToxiDetail />}/>
          <Route path="/travel-info" element={<TravelInfo />}/>
          <Route path="/presentation" element={<Presentation />}/>
        </Route>
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Router>
  );
}

export default App;