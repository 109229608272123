// src/SvgAnimation.jsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './SvgTrayAnimation.css';

const SvgTrayAnimation = () => {
    const pathRef = useRef(null);

    useEffect(() => {
      const path = pathRef.current;
      const length = path.getTotalLength();
  
      // Set up initial CSS properties for the path
      gsap.set(path, {
        strokeDasharray: length,
        strokeDashoffset: length,
      });
  
      // Animate the strokeDashoffset to 0
      gsap.to(path, {
        strokeDashoffset: 0,
        duration: 10, // Adjust duration as needed
        ease: 'power1.inOut',
      });
    }, []);
  
    return (
      <div className="svg-container">
        <svg viewBox="0 0 9000 5000" className="svg-content">
          <path
            ref={pathRef}
            d="M3000 4531 c0 -29 -35 -170 -66 -191 -50 -94 -202 -235 -399 -367
            -122 -82 -372 -232 -815 -490 -512 -298 -708 -418 -857 -521 -314 -217 -525
            -436 -589 -612 -9 -25 -17 -77 -17 -115 -1 -60 3 -80 28 -129 51 -100 170
            -188 303 -221 31 -8 143 -26 247 -40 301 -40 400 -62 459 -101 17 -11 15 -14
            -21 -33 -59 -30 -141 -97 -158 -130 -16 -31 -12 -54 13 -83 30 -35 130 -18
            186 31 46 40 70 110 30 170 -139 50 0 31 150 97 220 97 418 150 601 160 185 10
            272 -16 649 -190 368 -170 481 -207 633 -213 79 -2 102 -7 123 -23 101 -79
            390 -102 649 -52 55 11 314 69 575 130 758 175 1018 221 1245 221 159 0 249
            -29 281 -91 21 -42 19 -46 -38 -65 -79 -25 -165 -71 -182 -96 -23 -32 -20 -54
            12 -83 24 -24 33 -26 88 -23 49 3 69 10 99 32 42 32 78 103 73 144 -3 26 -1
            27 82 44 284 57 663 49 996 -22 394 -84 616 -124 754 -135 57 -5 92 -15 145
            -41 150 -73 389 -111 649 -101 177 7 262 24 601 120 440 125 598 157 773 157
            126 0 137 -5 122 -46 -24 -62 18 -134 79 -134 35 0 0 34 103 65 8 19 8 31 0
            50 -13 28 -67 65 -94 65 -42 0 -4 36 62 59 250 38 254 33 672 -24 284 -38 570
            -73 713 -86 162 -15 438 -15 527 0 60 10 74 9 125 -8 90 -30 249 -45 403 -37
            185 10 306 27 602 85 518 103 789 135 1113 135 249 0 345 -13 434 -56 l45 -22
            -96 -17 c-122 -21 -161 -34 -198 -66 -46 -38 -42 -76 13 -105 36 -19 57 -23
            142 -22 109 0 146 12 184 60 22 28 28 83 11 114 -9 16 -4 19 53 25 301 33 513
            75 621 124 66 30 156 108 156 136 0 23 -15 18 -30 -10 -32 -60 -148 -122 -295
            -159 -64 -16 "
            fill="transparent"
            stroke="black"
            strokeWidth="8"
          />
        </svg>
      </div>
    );
  };

export default SvgTrayAnimation;