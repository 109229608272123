import React from 'react';
import './DressCode.css';

function DressCode() {
  return (
    <div className="DressCode">
      <h1>DressCode</h1>
    </div>
  );
}

export default DressCode;
