import React, { useState } from 'react';
import './Presents.css';
import { useTranslation } from 'react-i18next';
import oliveTop from './../../elements/img/olive-page-top.png';
import oliveBottom from './../../elements/img/olive-page-bottom.png';

function Presents() {
  const [copy, setCopy] = useState(false);
  const { t } = useTranslation();

  const handleCopy = text => {
    const textElement = document.createElement('textarea');
    textElement.value = text;
    document.body.appendChild(textElement);
    textElement.select();
    document.execCommand('copy');
    document.body.removeChild(textElement);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  }

  return (
    <div className="present">
      <img className="olive-top" src={oliveTop}></img>
      <div className='presents-container'>
        <section className="present-section">
          <h1 className="presents-title">{t('menu-presents')}</h1>
          <p className="present-text">{t('presents-text-1')}</p>
          <p className="present-text">{t('presents-text-2')}</p>
          <p className="present-text">{t('presents-text-2-1')}</p>
          <p className="present-text">{t('presents-text-3')}</p>
          <p className="present-text">{t('presents-text-4')}</p>
        </section>
        <section className="present-section">
          <h3 className="presents-subtitle">{t('presents-subtitle-iban')}</h3>
          <p className="present-text-data">{t('presents-iban')} <span className={!copy ? "iban" : "copied"} onClick={() => handleCopy('DE06200411770677273500')}>{!copy ? "DE06 2004 1177 0677 2735 00" : t('presents-iban-copy')}</span></p>
          <p className="present-text-data">{t('presents-name')} Tim Schroeder & Amparo Ocana Martos</p>
          {/* <p className="present-text-data">{t('presents-concept')} {t('presents-concept-text')}</p> */}
        </section>
        <section className="present-section">
          <h3 className="presents-subtitle">{t('presents-questions')}</h3>
          <p className="present-text">{t('presents-questions-text')}</p>
          <section className="number-section">
            <a target="_blank" href="https://wa.me/4917698367300" className="contact-info">Tim</a>
            <a target="_blank" href="https://wa.me/4917657613455" className="contact-info">Ampi</a>
          </section>
          <p className="present-text">{t('presents-questions-greetings')}</p>
        </section>
      </div>
      <img className="olive-bottom" src={oliveBottom}></img>
    </div>
  );
}

export default Presents;
