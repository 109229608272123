import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import logo from './../../img/logo-white.png';
import './Navbar.css';
import { useTranslation } from 'react-i18next';

function Navbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
 
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSubMenuOpen = (event) => {
        setSubMenuAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setSubMenuAnchorEl(null);
    };

    const handleNavigate = (route, close) => {
        navigate(route);
        close();
    }

    const handleNavigateRSVP = (language) => {
      const link = document.createElement('a');
      link.target = '_blank';
        if(language === 'ES') {
            link.href = 'https://docs.google.com/forms/d/e/1FAIpQLSdQtQWZETWk-QlSNlp0XC90jiikPKRksWg8et4S3XcZ4qhj8Q/viewform';
        } else {
            link.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfF6a-w4MFFxvmk6vWwnn3zjQD14JcuhjwD-fm9fV_dWkA2qQ/viewform';
        }
      link.click();
    }

    
    const rsvpMenu = (
        <div>
        <MenuItem onClick={(event) => handleSubMenuOpen(event)}>{t('menu-register')}</MenuItem>
            <Menu
                id="simple-submenu"
                anchorEl={subMenuAnchorEl}
                keepMounted
                open={Boolean(subMenuAnchorEl)}
                onClose={handleSubMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {handleNavigateRSVP('ES'); handleClose()}}>{t('menu-register-spain')}</MenuItem>
                <MenuItem onClick={() => {handleNavigateRSVP('DE'); handleClose()}}>{t('menu-register-germany')}</MenuItem>
            </Menu>
        </div>
    )

    const mobileNavbar = (
        <div>
            <IconButton
                sx={ {display: { xs: 'block', sm:'block', md: 'none'}}}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                sx={{ xs: 'none', sm:'block'}}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={() => handleNavigate('/about-us', handleClose)}>{t('menu-about-us')}</MenuItem> */}
                <MenuItem onClick={() => handleNavigate('/presentation', handleClose)}>{t('menu-presentation')}</MenuItem>
                {rsvpMenu}
                <MenuItem onClick={() => handleNavigate('/program', handleClose)}>{t('menu-program')}</MenuItem>
                <MenuItem onClick={() => handleNavigate('/toxi-detail', handleClose)}>{t('menu-torri-detail')}</MenuItem>
                <MenuItem onClick={() => handleNavigate('/presents', handleClose)}>{t('menu-presents')}</MenuItem>
                {
                    !i18n.language.startsWith('es') && (
                    <>
                        <MenuItem onClick={() => handleNavigate('/travel-info', handleClose)}>{t('menu-travel-detail')}</MenuItem>
                    </>
                    )
                }
                </Menu>
        </div>
    );

    const desktopNavbar = (
        <Box sx={{display: { xs: 'none', sm:'none', md: 'flex'}}}>
            {/* <MenuItem onClick={() => handleNavigate('/about-us', handleClose)}>{t('Sobre nosotros')}</MenuItem> */}
            <MenuItem onClick={() => handleNavigate('/presentation', handleClose)}>{t('menu-presentation')}</MenuItem>
            {rsvpMenu}
            <MenuItem onClick={() => handleNavigate('/program', handleClose)}>{t('menu-program')}</MenuItem>
            <MenuItem onClick={() => handleNavigate('/toxi-detail', handleClose)}>{t('menu-torri-detail')}</MenuItem>
            <MenuItem onClick={() => handleNavigate('/presents',handleClose)}>{t('menu-presents')}</MenuItem>
            {
                !i18n.language.startsWith('es') && (
                <>
                    <MenuItem onClick={() => handleNavigate('/travel-info', handleClose)}>{t('menu-travel-detail')}</MenuItem>
                </>
                )
            }
        </Box>
    )
  
    return (
      <Box>
        <AppBar sx={ {backgroundColor: '#767238'}} position="static">
          <Toolbar>
            <img onClick={() => navigate('/home')} className="navbar-logo" src={logo} />
            <div style={{marginLeft: 'auto'}}>
                {desktopNavbar}
                {mobileNavbar}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    );
}

export default Navbar;