// src/Card.jsx
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Card.css';
import ButtonStyled from './../buttonStyled/ButtonStyled.js'
import './../../../i18n.js'
import { Warning } from '@mui/icons-material';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import EuroIcon from '@mui/icons-material/Euro';
import TapasIcon from '@mui/icons-material/Tapas';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ThermostatIcon from '@mui/icons-material/Thermostat';

const Card = ({ image, svgClass, title, description, buttonText, onButtonClick, imagePosition }) => {
    const { t } = useTranslation();

    const svgObj = {
        "Warning": Warning,
        "Water": FormatColorResetIcon,
        "Tips": EuroIcon,
        "Sunscreen": WbSunnyIcon,
        "Tapas": TapasIcon,
        "Thermostat": ThermostatIcon

    };

    const renderSvg = (tag) => {
        const SvgCmp = svgObj[tag]; 
        return (
            <SvgCmp className="svg-transform"/>
        )
    }
    return (
        <div className={`card ${imagePosition === 'left' ? 'image-left' : 'image-right'}`}>
            {
                image ? 
                <img src={image} alt={t(title)} className="card-image" /> :
                ''
            }
            {
                svgClass ?
                renderSvg(svgClass) : ''
            }
            <div className={`card-content ${imagePosition === 'left' ? 'text-left' : 'text-right'}`}>
                <h2 className="card-title">{t(title)}</h2>
                <p className="card-description">
                    <Trans
                        i18nKey={description}
                        components={{ 1: <br /> }}
                    />
                </p>
                {
                    buttonText ?
                        <ButtonStyled limitless={true} text={buttonText} handleClick={onButtonClick}></ButtonStyled> :
                        ''
                }
            </div>
        </div>
    );
};

export default Card;