import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // Carga la traducción usando http -> ve a /public/locales y carga los archivos de idiomas
  .use(HttpBackend)
  // Detecta el idioma del usuario
  .use(LanguageDetector)
  // pasa el i18n hacia abajo a react-i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // idioma a cargar si no se encuentra otro
    debug: false, // Poner en false en producción
    interpolation: {
      escapeValue: false, // no es necesario escapar el contenido en React
    }
  });

export default i18n;
