import React from 'react';
import './Program.css';
import icon from './../../elements/img/logo-program.png'
import { useTranslation } from 'react-i18next';
import StarAnimation from '../../elements/component/starAnimation/StarAnimation.js';
import CalendarBtn from '../../elements/component/calendarBtn/CalendarBtn.js';

function Program() {
  const { t } = useTranslation();
  const event = {
    title: 'event-title',
    description: 'event-description',
    location: 'Cortijo El Madroño, Km Carretera, 7, 23600 Santiago de Calatrava, Jaén, España',
    startTime: new Date('2024-09-28T13:00:00'),
    endTime: new Date('2024-09-29T02:00:00')
  }

  return (
    <div className="Program">
      <div className="container-program">
          <img src={icon}>
          </img>
          <h1 className='text'>
            28.09.2024
          </h1>
          <p className='subtext-program'>13:00 {t('program-wedding')}</p>
          <p className='subtext-pass'>CORTIJO EL MADROÑO</p>
          <p className='subtext-pass'>MARTOS, JAÉN</p>
      </div>
      <div className='img-container-row'>
        <StarAnimation className="animation-box" text={t("program-ceremony")} subText={"13:00 "+t("countdown-timer-hour-min")} idxClass="1" imgSrc={"ceremonia.png"}></StarAnimation>
        <StarAnimation className="animation-box" text={t("program-coctel")} subText={"14:00 "+t("countdown-timer-hour-min")} idxClass="2" imgSrc={"cocktail.png"}></StarAnimation>
        <StarAnimation className="animation-box" text={t("program-menu")} subText={"15:30 "+t("countdown-timer-hour-min")} idxClass="3" imgSrc={"menu.png"}></StarAnimation>
      </div>  
      <div className='img-container-row'>
        <StarAnimation className="animation-box" text={t("program-party")} subText={"18:00 "+t("countdown-timer-hour-min")} idxClass="4" imgSrc={"fiesta.png"}></StarAnimation>
        <StarAnimation className="animation-box" text={t("program-bus")} subText={"23:00 "+t("countdown-timer-hour-min")} idxClass="5" imgSrc={"vuelta-bus.png"}></StarAnimation>
        <StarAnimation className="animation-box" text={t("program-after-party")} subText={"23:30 "+t("countdown-timer-hour-min")} idxClass="6" imgSrc={"after-party.png"}></StarAnimation>
      </div>
      <div className='calendar-container'>
        <CalendarBtn {...event} />
      </div>
    </div>
  );
}

export default Program;
