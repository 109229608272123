import React from 'react';
import './Witnesses.css';

function Witnesses() {
  return (
    <div className="Witnesses">
      <h1>Witnesses</h1>
    </div>
  );
}

export default Witnesses;
