import React from 'react';
import './Presentation.css';
import ButtonStyled from './../../elements/component/buttonStyled/ButtonStyled.js'
import { useTranslation } from 'react-i18next';
import PresentationImg from './../../elements/img/presentation-img.jfif';
import olives from './../../elements/img/olives.png';

function Presentation() {
  const { t } = useTranslation();


  const handleButtonClick = (isSpain) => {
    const elm = document.createElement('a');
    const url = isSpain
      ? 'https://docs.google.com/forms/d/e/1FAIpQLSdQtQWZETWk-QlSNlp0XC90jiikPKRksWg8et4S3XcZ4qhj8Q/viewform'
      : 'https://docs.google.com/forms/d/e/1FAIpQLSfF6a-w4MFFxvmk6vWwnn3zjQD14JcuhjwD-fm9fV_dWkA2qQ/viewform'

    elm.href = url;
    elm.target = '_blank';
    elm.rel = 'noopener noreferrer';

    elm.click();
  }


  return (
    <div className="Presentation">
      <div className="container">
        <img src={PresentationImg} alt="presentation-image" className="hero-image" />
        <div className="separator-container">
          <img src={olives} className="separator inverted"/>
          <img src={olives} className="separator"/>
        </div>
        <div className="info-container">
          <div className="text-content">
              <p className='presentation-title'>{t('presentation-title')}</p>
              <p className='presentation-subtitle'>{t('presentation-subtitle')}</p>
          </div>
          <div className="button-container">
              <ButtonStyled className="button" limitless={true} text={t("presentation-spain")} handleClick={() => handleButtonClick('spain')}/>
              <ButtonStyled className="button" limitless={true} text={t("presentation-foreign")} handleClick={() => handleButtonClick()}/>
          </div>
        </div>
      </div>
      </div>
  );
}

export default Presentation;
