import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './../../context/AuthContext.js';
import Navbar from '../../elements/component/navBar/Navbar.js';
import './ProtectedRoute.css'

function ProtectedRoute() {
  const { auth } = useContext(AuthContext);

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <div className='protected-container'>
        <div className="protected-navbar"><Navbar  /></div>
        <div className='protected-outlet'><Outlet /></div>
      </div>
    </div>
  );
}

export default ProtectedRoute;