import './Login.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext.js';
import olives from './../../elements/img/olives.png';
import InputText from '../../elements/component/inputText/InputText.js';
import ButtonStyled from '../../elements/component/buttonStyled/ButtonStyled.js';

function Login() {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = () => {
    if (password === "280924") {
      setAuth(true);
      navigate('/home');
    } else {
      setError(true);
    }
  };

  const handleOnChange = (e) => {setError(false); setPassword(e.target.value)}
  const handleEnterKeyDown = (e) => {
    if (e.keyCode === 13) {
        handleLogin()
        return;
    } else {
        return true
    }
  }

  return (
    <div className="Login">
      <header className="Login-header">
        <div className="container-wedding">
          <h1 className='text'>
            Tim & Ampi
          </h1>
          <p className='subtext-pass'>{t('login-password')}</p>
          <div className='form-container'>
            <InputText
                type="password"
                error={error}
                value={password}
                handleChange={(e) => handleOnChange(e)}
                handleKeyDown={(e) => handleEnterKeyDown(e)}
                placeholderText={t("login-password-placeholder")}
            />
            <ButtonStyled text={t("login-enter")} handleClick={handleLogin}/>
          </div>  
        </div>    
      <img
          src={olives}
          className="top-olives"
          alt=""
        >
        </img>
      <img
          src={olives}
          className="bottom-olives"
          alt=""
        ></img>
      </header>
    </div>
  );
}

export default Login;
