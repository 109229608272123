import React from 'react';
import './TravelInfo.css';
import Card from '../../elements/component/Card/Card';

function TravelInfo() {
  return (
    <div className="TravelInfo">
      <Card
        svgClass={"Warning"}
        title='travel-info-feiertag-title'
        description='travel-info-feiertag-description'
        imagePosition="left" // or "right"
      />
      <Card
        svgClass={"Water"}
        title='travel-info-trinkwasser-title'
        description='travel-info-trinkwasser-description'
        imagePosition="left" // or "right"
      />
      <Card
        svgClass={"Tips"}
        title='travel-info-trinkgeld-title'
        description='travel-info-trinkgeld-description'
        imagePosition="left" // or "right"
      />
      <Card
        svgClass={"Sunscreen"}
        title='travel-info-sonnenschutz-title'
        description='travel-info-sonnenschutz-description'
        imagePosition="left" // or "right"
      />
      <Card
        svgClass={"Tapas"}
        title='travel-info-tapas-title'
        description='travel-info-tapas-description'
        imagePosition="left" // or "right"
      />
      <Card
        svgClass={"Thermostat"}
        title='travel-info-temperaturen-title'
        description='travel-info-temperaturen-description'
        imagePosition="left" // or "right"
      />
    </div>
  );
}

export default TravelInfo;
