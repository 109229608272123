import React, { useState, useEffect } from 'react';
import './CountdownTimer.css'
import { useTranslation } from 'react-i18next';

function CountdownTimer({ targetDate }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
    const { t } = useTranslation();

    function calculateTimeLeft(date) {
        const difference = +new Date(date) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearTimeout(timer);
    });

    // Formato de visualización del tiempo restante
    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {

        timerComponents.push(
            <span className="interval-span" key={interval}>
                <div className="interval-span-number">
                    {timeLeft[interval]} 
                </div>
                <div className="interval-span-literal">
                    {t(`countdown-timer-${timeLeft[interval] === 1 ? interval.slice(0,-1): interval}`)}{" "}
                </div>
            </span>
        );
    });

    return (
        <div className='countdowntimer-container'>
            {Object.keys(timeLeft).filter(elm => timeLeft[elm]).length ? timerComponents : <span>{t("countdown-timer-wedding")}</span>}
        </div>
    );
}

export default CountdownTimer;